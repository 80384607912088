"use client";

import { Box, Button, Divider, Typography } from "@mui/material";
import Image from "next/legacy/image";
import React, { useMemo } from "react";

// import StarRoundedIcon from "@mui/icons-material/StarRounded";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import FavoriteBorderRoundedIcon from "@mui/icons-material/FavoriteBorderRounded";
import { ProductProps } from "@/type/product";

import { useCartDrawer } from "@/context/cart";
import Link from "next/link";
import { Company } from "@/type/company";
import { useTranslation } from "@/app/i18n/client";

type Props = {
  product: ProductProps;
  company: Company;
  lang: string;
};

export default function ProductCard({ product, company, lang }: Props) {
  const {
    id,
    productConfigs,
    name,
    subDescription,
    coverUrl,
    regularPrice,
    productNumber,
    isOnSale,
    onSalePrice,
    slug,
  } = product;

  const { t } = useTranslation(lang, "product", {});

  const { addProductToCart } = useCartDrawer();

  return (
    <Box
      sx={{
        // minWidth: { lg: "235px" },
        boxShadow: "0px 0px 10px -2px rgba(130, 0, 0, 0.04)",
        borderRadius: "4px",
        px: '16px',
        py: { xs: "16px", lg: "24px" },
        pb: { xs: "16px", lg: "32px" },
        position: "relative",
        cursor: "pointer",
      }}
    >
      {company?.settings?.showFavoritesPage && (
        <Box
          sx={{
            position: "absolute",
            top: { xs: "10px", lg: "8px" },
            right: { xs: "16px", lg: "8px" },
            zIndex: "1",
            width: "30px",
            height: "30px",
            borderRadius: "50%",
            background: "white",
            display: { xs: "none", lg: "flex" },
            justifyContent: "center",
            alignItems: "center",
            cursor: "pointer",
          }}
        >
          <FavoriteBorderRoundedIcon sx={{ color: "rgba(0, 0, 0, 0.4)" }} />
        </Box>
      )}

      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "row", sm: "column" },
          gap: { xs: "12px", lg: "16px" },
        }}
      >
        <Link href={`/product/${slug ?? productNumber}`}>
          <Box
            sx={{
              mx: { sm: "auto" },
              borderTopLeftRadius: "4px",
              borderTopRightRadius: "4px",
              boxShadow: "2px 2px 15px 0px rgba(170, 170, 170, 0.15)",
              position: "relative",
              width: { xs: "100px", sm: "100%" },
              height: { xs: "100%", sm: "165px" },
            }}
          >
            {coverUrl && (
              <Image
                src={coverUrl}
                alt="product name"
                layout="fill"
                objectFit="contain"
                loading="lazy"
              />
            )}
          </Box>
        </Link>

        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '4px', width: "100%" }}>
          {/* Product Name and Like Button */}
          <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            <Typography
              className="block-text text-1"
              sx={{ color: "secondary.main", fontSize: { xs: "14px", lg: "18px" }, p: "auto", fontWeight: 600 }}
            >
              {name}
            </Typography>

            {/*TODO: enable this section when rating functionality is implemented*/}
            {/* <Box sx={{ display: "flex", alignItems: "center", gap: "4px" }}>
              <Box
                sx={{
                  display: "flex",
                  gap: "4px",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "46px",
                  height: { xs: "21px", lg: "24px" },
                  borderRadius: "17px",
                  border: "0.5px solid rgba(234, 168, 0, 1)",
                }}
              >
                <Typography
                  sx={{
                    color: "rgba(234, 168, 0, 1)",
                    fontSize: "12px",
                    fontWeight: "800",
                  }}
                >
                  4.5
                </Typography>
                <StarRoundedIcon
                  sx={{ color: "rgba(234, 168, 0, 1)", fontSize: "10px" }}
                />
              </Box>

              <Typography
                sx={{ color: "rgba(0, 0, 0, 0.6)", fontSize: "12px" }}
              >
                {"(15)"}
              </Typography>
            </Box> */}
          </Box>
          {/* Product Description */}
          <Typography
            className="block-text text-3"
            sx={{ minHeight: "50px", height: "50px", overflow: "hidden", fontSize: { xs: "12px", lg: "14px" }, color: "rgba(0, 0, 0, 0.8)", lineHeight: "16px", flex: 1 }}
          >
            {subDescription}
          </Typography>
          {/* Product Price */}
          <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-end', gap: '8px', mt: { xs: "8px", lg: "12px" },}}>
            {isOnSale && (
              <Typography sx={{ fontSize: "16px", color: "secondary.main", fontWeight: "700" }} >
                {onSalePrice} {t(company?.currency || "USD")}
              </Typography>
            )}
            <Typography
              sx={{
                fontSize: isOnSale ? "14px" : "16px",
                color: isOnSale ? "rgba(0, 0, 0, 0.4)" : "secondary.main",
                fontWeight: isOnSale ? "400" : "700",
                textDecoration: isOnSale ? "line-through" : "unset",
                width: "max-content",
              }}
            >
              {regularPrice} {t(company?.currency || "USD")}
            </Typography>
          </Box>
          {/* Add to Cart */}
          <Button
            fullWidth
            sx={{
              mt: { xs: "8px", lg: "12px" },
              height: { xs: "32px", lg: "40px" },
              borderRadius: "4px",
              border: "1px solid",
              borderColor: "secondary.main",
              color: "secondary.main",
              display: "flex",
              alignItems: "center",
              gap: {xs: "4px", lg: "8px"},
              textTransform: "none",
              "&:hover": {
                bgcolor: company?.activeTheme === "matjr-original" ? "primary.main" : "#000",
                color: "white",
                border: "none",
              },
            }}
            onClick={() => addProductToCart(product, 1)}
          >
            <AddShoppingCartIcon
              sx={{ fontSize: { xs: "14px", lg: "24px" } }}
            />
            <Typography sx={{ fontSize: { xs: "12px", sm: "14px", lg: "16px" } }}>
              {t("add-cart")}
            </Typography>
          </Button>
        </Box>
      </Box>
    </Box>
  );
}
